.header {
    padding: 17.5px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1e1c1c;
}

.nav {
    display: flex;
    gap: 20px;
}

.link {
    text-decoration: none;
    font-weight: 800;
    padding: 10px 20px 10px 20px;
    border-radius: 20px;
    background-color: black;
}

@media screen and (max-width: 1025px) {
    .header {
        flex-direction: column;
        gap: 15px;
    }
}