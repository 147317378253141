.container {
    height: 100svh;
    height: 100vh;
    background:
    linear-gradient(to top, black, #707275 100%);
    background-origin: border-box;
}

.colorWrapper{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.textContainer {
    text-shadow: 3px 2px black;
    width: 60%;
    padding-bottom: 150px;
}

.heading {
    margin: 0;
    font-size: 30pt;
    font-weight: 800;
}

.description {
    font-size: 18pt;
    line-height: 150%;
}

.linkedInLink {
    font-weight: 800;
    text-decoration: none;
    color: #0072B1;
}

.linkedInLink:hover {
    text-decoration: underline;
}

@media screen and (max-width: 1025px) {
    .container {
        height: auto;
    }

    .textContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 50px;
        width: 100%;
        padding: 75px 20px;
    }

    .heading {
        font-size: 24pt;
    }

    .description {
        font-size: 14pt;
    }
}