.footer {
    padding: 17.5px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1e1c1c;
}

.nav {
    display: flex;
    gap: 20px;
}

.link {
    display: flex;
    justify-content: center;
}

.githubIcon {
    width: 30px;
    height: 30px;
    margin-right: 5px;
}

.githubLogotype {
    opacity: 0;
    width: 70px;
    transition: opacity 1s;
}

.githubLogotypeActive {
    width: 70px;
    opacity: 100%;
    transition: opacity 1s;
}

.linkedinIcon {
    width: 35px;
    height: 30px;
}

.linkedinLogotype {
    opacity: 0;
    width: 85px;
    transition: opacity 1s;
}

.linkedinLogotypeActive {
    width: 85px;
    opacity: 100%;
    transition: opacity 1s;
}

@media screen and (max-width: 500px) {
    .linkedinLogotype {
        width: 0;
    }

    .githubLogotype {
        width: 0;
    }
}