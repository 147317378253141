.img {
    height: 100%;
    width: 50%;
}

.heroContainer {
    flex-grow: 1;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: black;
    padding: 30px 100px 0 0;
    overflow: hidden;
}

.text {
    position: absolute;
    top: 40%;
    left: 10%;
}

.heading {
    margin: 0;
    font-size: 5vw;
    font-weight: 800;
}

.subText {
    font-size: 16pt;
}

.second {
    margin-top: 15px;
}

.line {
    background-color: white;
    position: absolute;
    transform-origin: bottom left;
    height: 150%;
    width: 3px;
    border-radius: 10px;
    right: 0;
    bottom: 0;
    rotate: -55deg;
}

@media screen and (max-width: 1025px) {
    .heroContainer {
        flex-direction: column;
        justify-content: space-between;
        padding: 30px 0 0 0;
    }

    .text {
        padding: 20px 20px;
        text-align: center;
        position: relative;
        top: 0;
        left: 0;
    }

    .heading {
        font-size: 8vw;
    }

    .subText {
        font-size: 4vw;
    }

    .img {
        width: 200%;
        height: 70%;
        object-fit: cover;
        object-position: 100% 0;
    }
}