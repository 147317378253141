.container {
    height: 100svh;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
    margin-bottom: 100px;
}

.slideContainer {
    width: min-content;    
}

.slideControls {
    position: absolute;
    top: 25%;
    left: 0;
    transform: translate(-150%, 25%);
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.slideButton {
    padding: 0;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    background-color: black;
}

.arrowIcon {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.hide {
    opacity: 0;
    pointer-events: none;
}

.heading {
    font-size: 30pt;
    font-weight: 700;
}

.image {
    animation: image 1.5s ease-out;
    border-radius: 10px;
    width: 837px;
    height: 391px;
}

.workTitle {
    font-size: 18pt;
    font-weight: 800;
    margin: 0;
}

.textContainer {
    background: linear-gradient(90deg, #fff, #fff, #fff);
    background-size: 100%;
    background-repeat: no-repeat;
    animation: text 1.5s ease-out;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: rgba(255, 255, 255, 0);
    -webkit-text-stroke-color: rgba(255, 255, 255, 0);
}

.mobile {
    display: none;
}

.iconContainer {
    display: flex;
    align-items: center;
    margin: 20px 0 0 0;
    gap: 10px;
}

.techniqueIcon {
    animation: image 1.5s ease-out;
    width: 30px;
}

.link {
    width: max-content;
    display: block;
    font-size: 14pt;
    font-weight: 800;
    text-decoration: none;
    background-color: grey;
    padding: 10px 20px;
    border-radius: 20px;
    margin: 20px auto 0 0;
    animation: image 1.5s ease-out;
}

@media screen and (max-width: 1025px) {

    .heading {
        font-size: 24pt;
    }

    .image {
        width: 90vw;
        height: 300px;
        object-fit: cover;
        object-position: 0 100%;
    }

    .container {
        height: auto;
        padding: 10px;
    }

    .slideControls {
        position: static;
        transform: none;
        flex-direction: row;
        justify-content: center; 
        gap: 25px;
        margin-top: 10px;
    }

    .slideButton {
        width: 50px;
        height: 50px;
    }

    .link {
        margin-left: auto;
    }

    .desktop {
        display: none;
    }

    .mobile {
        display: flex;
    }
}

@media screen and (min-width: 2000px) {
    .image {
        width: 1000px;
        height: 467px;
    }
}


@keyframes image {
    0% {
        opacity: 0;
    }

    100% { 
        opacity: 100%;
    }
}

@keyframes text {
    0% {
        background-position: 0 -200px;
    }
    100% {
        background-position: 0 0;
    }
}