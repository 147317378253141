* {
    color: white;
    font-family: "Nanum Myeongjo", serif;
    font-weight: 400;
    font-style: normal;
}

body {
    background-color: black;
}

.left-align {
    text-align: left;
}

.position-relative {
    position: relative;
}

.no-margin {
    margin: 0;
}

.viewport-div {
    display: flex;
    flex-direction: column;
    height: 100svh;
    height: 100vh;
}

.initials {
    font-size: 14pt;
    font-weight: 800;
    letter-spacing: 5px;
}

.hidden-initials {
    visibility: hidden;
}

@media screen and (max-width: 1025px) {
    .hidden-initials {
        display: none;
    }
}